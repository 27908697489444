import { useLocation } from "@reach/router";
import { PlusOutlined } from "@ant-design/icons";
import { Badge, Button, Carousel, message, Popover, Tooltip } from "antd";
import { Link, navigate } from "gatsby";
import { cloneDeep, compact, get, isEmpty, map, find } from "lodash";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { updateActiveId } from "../../../redux/actions/product-actions";
import { routes } from "../../../route";
import Layout from "../../general/components/Layout";
import CoinDetails from "../../general/components/MainCoinsDetails";
import { navItems1 } from "../../general/config";
import generateDemoInternalAccessKey from "../../../newApi/profile/generateDemoInternalAccessKey";
import getPairingUser from "../../../newApi/pairing/getPairingUser";

// import ProfitDetails from "./ProfitDetails";
// import { Textfit } from "react-textfit";
import {
  bellIconImg,
  blueBanner2Img,
  blueBanner3Img,
  blueBanner4Img,
  blueBanner5Img,
  comunityIcon,
  logoutIconImg,
  newLogoImg,
  notificationImg,
  robotBannerImg,
  speakerImg,
  threeDotImg,
  usdtIcon,
} from "../../../images";
// import Scrollbars from "react-custom-scrollbars";
import getProfiles from "../../../newApi/profile/getProfiles";
import ScrollLoadWrapper from "../../general/components/ScrollLoadWrapper ";
// import getNotifications from "../../../newApi/notification/getNotifications";
import patchRead from "../../../newApi/notification/patchRead";
import {
  clearModal,
  triggerModal,
  updateGlobalModalVisible,
} from "../../../redux/actions/app-actions";
// import IncomeCapacity from "../../rewardExplore/components/IncomeCapacity";
// import RewardListing from "./RewardListing";
import moment from "moment";
import Marquee from "react-fast-marquee";
import { sourceKey } from "../../../locales/config";
import { useTranslation } from "../../../locales/useTranslation";
import getCampaigns from "../../../newApi/campaign/getCampaigns";
import { logoutSuccessful } from "../../../redux/actions/user-actions";
import AccountSummary from "./AccountSummary";
import DownloadPopUpModal from "./DownloadPopUpModal";
// import getBanners from "../../../newApi/banner/getBanners";
import getBanners from "../../../newApi/campaign/getBanners";
import { languageToNumber } from "../../../utilities/profile";
import LightBoxGallery from "../../general/components/LightBoxGallery";
import getRobotProfiles from "../../../newApi/robots/getRobotProfiles";
import getNotifications from "../../../newApi/notification/getNotifications";
import { forEach } from "lodash";
import patchReadAll from "../../../newApi/notification/patchReadAll";
import generateDemoAccessKey from "../../../newApi/profile/generateDemoAccessKey";
import queryString from "query-string";
import moment2 from "moment-timezone";
import { timeZone } from "../../../utilities/startUp";
import { allBannerData } from "../../../utilities/data";
import AlertModal from "../../general/components/AlertModal";
import { allItems1, defaultItems } from "../../general/config";
import { feathers } from "../../../api/feathers_rest";
import ShortKeysMenuModal from "./ShortKeysMenuModal";
import patchShortKeys from "../../../newApi/profile/patchShortKeys";

const PAGE_SIZE = 15;
const imageHeight = 250;
// const positiveColor = "#4BB543";
// markup
const HomePage = (props) => {
  const location = useLocation();
  const { t, languages } = useTranslation();
  // const [textIndex, setTextIndex] = React.useState(0);
  const [profile, setProfile] = useState({});
  const [notificationRead, setNotificationRead] = useState(false);
  const [notificationCount, setNotificationCount] = useState(0);
  const [notificationPage, setNotificationPage] = useState(1);
  const [marqueeData, setMarqueeData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [readAllLoading, setReadAllLoading] = useState(false);
  const [notificationData, setNotificationData] = useState([]);
  const [downloadPopUpVisible, setDownloadPopUpVisible] = useState(false);
  const [shortKeys, setShortKeys] = useState([]);
  const [menusVisible, setMenusVisible] = useState(false);
  const [banners, setBanners] = useState([]);
  const [pairingUser, setPairingUser] = useState({});
  let accessKey = get(props.user, "accessKey");
  let user = get(props, "user.user");
  const bannerData = get(allBannerData, languages);

  useEffect(() => {
    getData();
    getProfile();
    checkIsRead();
    getBannerData();
    getPairingUserId();
  }, []);

  useEffect(() => {
    getNotificationsData((notificationPage - 1) * PAGE_SIZE);
  }, [notificationPage]);

  useEffect(() => {
    if (!isEmpty(profile)) {
      let notifiedTime = new Date(get(props.app, "modalNotifyTime"));
      let currentTime = new Date();

      let robotProfiles = get(profile, "robotProfiles");
      let notify = false;

      if (notifiedTime !== null) {
        const diffInMs = Math.abs(currentTime - notifiedTime);
        const TransactionDayDiff = diffInMs / (1000 * 60 * 60 * 24);

        if (TransactionDayDiff >= 1) {
          notify = true;
        }
      } else {
        notify = true;
      }

      if (notify) {
        robotProfiles = map(robotProfiles, "robotProfileId");
        if (isEmpty(robotProfiles)) {
          return;
        }
        getRobotProfiles({
          _idIn: robotProfiles,
        })
          .then((res) => {
            let profilesData = get(res, "data");
            forEach(profilesData, (item, index) => {
              let exchange = get(item, "exchange");
              if (get(item, "status") === 1) {
                props.triggerModal({
                  type: `${exchange}ApiKeyInvalidError`,
                  redirection: routes.apiKey.to(),
                });
              } else if (get(item, "status") === 3) {
                props.triggerModal({
                  type: `suspend`,
                  redirection: routes.apiKey.to(),
                  exchange: exchange,
                });
              }

              /// for wallet limit checking
              let limit = get(item, "robotFundLimit.highFrequency.limit");
              let maxAmount = limit * 0.98;

              if (
                get(item, "robotFundLimit.highFrequency.enable") === 1 &&
                get(item, "robotFundLimit.highFrequency.used") > maxAmount
              ) {
                props.triggerModal({
                  type: `walletLimit`,
                  redirection: routes.highFrequency.to({
                    platform: exchange,
                  }),
                  exchange: exchange,
                });
              }
            });
          })
          .catch((err) => {});
      }

      let profileShortKey = get(profile, "shortKey");

      if (isEmpty(profileShortKey)) {
        let defaultShortKeyItem = map(defaultItems, (item, index) => {
          return item.value;
        });
        let insertShortKey = cloneDeep(defaultShortKeyItem);
        profileShortKey = defaultShortKeyItem || [];

        while (profileShortKey.length < 10) {
          profileShortKey.push("");
        }

        // feathers("profiles", accessKey)
        //   .patch(get(profile, "_id"), {
        //     shortKey: insertShortKey,
        //   })
        patchShortKeys({
          shortKeys: insertShortKey,
        })
          .then((res) => {
            setShortKeys(profileShortKey);
          })
          .catch((err) => {
            
          });
      } else {
        while (profileShortKey.length < 10) {
          profileShortKey.push("");
        }
        setShortKeys(profileShortKey);
      }
    }
  }, [profile]);

  const contentStyle = {
    height: `${imageHeight}px`,
    color: "#fff",
    lineHeight: "160px",
    textAlign: "center",
    margin: 5,
    // objectFit: "cover",
    // background: "#364d79",
    // borderRadius: "60px",
    // borderBottomRightRadius: "60px"
  };

  function getData() {
    // setLoading(true);
    // let startDate = new Date();
    let startDate = moment2().tz(timeZone).subtract(2, "d");
    // startDate = startDate.setDate(startDate.getDate() - 2);
    let endDate = moment2().tz(timeZone);
    startDate = moment2(startDate).tz(timeZone).format("YYYY-MM-DD");
    endDate = moment2(endDate).tz(timeZone).format("YYYY-MM-DD");
    startDate = moment2(startDate + " " + "00:00").tz(timeZone);
    endDate = moment2(endDate + " " + "23:59").tz(timeZone);

    getCampaigns(
      "all",
      0,
      {
        subscriber: get(user, "profile._id"),
        createdDateFrom: startDate.toDate(),
        createdDateTo: endDate.toDate(),
      },
      accessKey
    )
      .then((res) => {
        let data = get(res, "data");
        // setTotal(get(res, "total"));
        setMarqueeData(data);
        // setLoading(false);
      })
      .catch((err) => {
        // setLoading(false);
      });
  }

  function getNotificationsData(skip) {
    getNotifications(PAGE_SIZE, skip, {
      profileId: get(props.user, "user.profile._id"),
    })
      .then((res) => {
        let data = get(res, "data");
        if (!isEmpty(data)) {
          let temp_data = cloneDeep(notificationData);
          forEach(data, (item, index) => {
            temp_data.push(item);
          });
          setNotificationData(temp_data);
        }
      })
      .catch((err) => {});
  }

  function checkIsRead() {
    getNotifications("all", 0, {
      profileId: get(props.user, "user.profile._id"),
      isRead: 0,
    })
      .then((res) => {
        let data = res[0];
        if (!isEmpty(data)) {
          let notifyItem = data?.notifyItem || [];
          setNotificationRead(true);
          setNotificationCount(notifyItem?.length);
        } else {
          setNotificationCount(0);
        }
      })
      .catch((err) => {});
  }

  function getProfile() {
    getProfiles(1, 0, { _id: get(props.user, "user.profile._id") })
      .then((res) => {
        setProfile(get(res, "data[0]") || {});
      })
      .catch((err) => {});
  }

  function getPairingUserId() {
    getPairingUser(1, 0, {
      _id: get(props.user, "user.profile.pairingUserId"),
    }).then((res) => {
      setPairingUser(get(res, "data[0]") || {});
    });
  }

  function getBannerData() {
    let filteredGroup = {};
    filteredGroup.languageType = languageToNumber(languages);
    getBanners(
      "all",
      0,
      {
        ...filteredGroup,
        sort: {
          sequence: 1,
        },
      },
      accessKey
    )
      .then((res) => {
        setBanners(get(res, "data") || {});
      })
      .catch((err) => {});
  }

  function readAllNotifications() {
    setReadAllLoading(true);
    patchReadAll({
      profileId: profile?._id,
    })
      .then((res) => {
        setReadAllLoading(false);
        message.success(t("success"), 2);
        let tempData = cloneDeep(notificationData);
        tempData = map(tempData, (item, index) => {
          item.notifyItem.isRead = 1;
          return item;
        });
        setNotificationData(tempData);
        checkIsRead();
      })
      .catch((err) => {
        message.error(err?.message);
        setReadAllLoading(false);
      });
  }

  const _renderNotifications = () => {
    const clickNotification = (item) => {
      let redirectURL = get(item, "notifyItem.redirectURL");

      let _id = get(item, "_id");

      if (get(item, "notifyItem.isRead") === 1) {
        navigate(redirectURL);
      } else {
        patchRead(
          { _id: _id, notifyItemId: get(item, "notifyItem._id") },
          accessKey
        )
          .then((res) => {
            navigate(redirectURL);
          })
          .catch((err) => {});
      }
    };

    return (
      <div>
        <ScrollLoadWrapper
          style={{ height: 300, width: 300 }}
          onScrolledBottom={() => {
            let length = cloneDeep(notificationPage);
            setNotificationPage(length + 1);
          }}
          className=""
        >
          {isEmpty(notificationData) ? (
            <div className="items-center justify-center mt-10 ">
              <img
                src={notificationImg}
                className=" object-contain h-full w-full"
                style={{ height: "15vh" }}
              />
              <br></br>
              <div className="flex justify-center items-center">
                <div className="flex justify-center items-center font-black text-lg text-center mb-2 text-gray-400">
                  {t("noNotification", sourceKey.home)}
                </div>
              </div>
            </div>
          ) : (
            <div className="">
              {map(notificationData, (item, index) => {
                // let dateNow = new Date();
                let dateNow = moment2().tz(timeZone);
                // let createdDate = new Date(get(item, "notifyItem.createdDate"));
                let createdDate = moment2(
                  get(item, "notifyItem.createdDate")
                ).tz(timeZone);
                // let diffMs = dateNow - createdDate; // milliseconds between now & Christmas
                let diffMs = dateNow.diff(createdDate); // milliseconds between now & Christmas

                // let diffDays = Math.floor(diffMs / 86400000); // days
                let diffDays = dateNow.diff(createdDate, "days"); // days

                // let diffMonths =
                //   dateNow.getMonth() -
                //   createdDate.getMonth() +
                //   12 * (dateNow.getFullYear() - createdDate.getFullYear());
                let diffMonths = dateNow.diff(createdDate, "months");
                let diffYears = dateNow.diff(createdDate, "years");
                // let diffYears =
                //   dateNow.getFullYear() - createdDate.getFullYear();
                // let diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
                let diffHrs = dateNow.diff(createdDate, "hours");
                // let diffMins = Math.round(
                //   ((diffMs % 86400000) % 3600000) / 60000
                // ); // minutes
                let diffMins = dateNow.diff(createdDate, "minutes");

                let logo = "";
                if (get(item, "notifyItem.type") === 1) {
                  logo = comunityIcon;
                } else {
                  logo = usdtIcon;
                }

                return (
                  <div
                    className="border-b flex border-gray-400	"
                    style={{
                      backgroundColor: `${
                        get(item, "notifyItem.isRead") === 1 ? "" : "#CAE9F5"
                      }`,
                      cursor: "pointer",
                      color: "black",
                    }}
                    onClick={() => {
                      clickNotification(item);
                    }}
                  >
                    <div
                      className="flex justify-center items-center "
                      style={{ width: 80 }}
                    >
                      <div className="rounded-full border-2 p-2 border-gray-400	ml-2">
                        <img src={logo} style={{ width: 55 }} />
                      </div>
                    </div>
                    <div className="ml-2 p-1" style={{ width: 385 }}>
                      <span>{get(item, "notifyItem.description")}</span>
                      <br />
                      <span style={{ color: "rgb(148 163 184)", fontSize: 13 }}>
                        {diffYears === 0
                          ? diffMonths === 0
                            ? diffDays === 0
                              ? diffHrs === 0
                                ? diffMins < 2
                                  ? "Just now "
                                  : diffMins + " minutes ago"
                                : diffHrs === 1
                                ? "An hour ago"
                                : diffHrs + " hours ago"
                              : diffDays === 1
                              ? "A day ago"
                              : diffDays + " days ago"
                            : diffMonths === 1
                            ? "A month ago"
                            : diffMonths + " months ago"
                          : diffYears === 1
                          ? "1 year ago"
                          : diffYears + " years ago"}
                      </span>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </ScrollLoadWrapper>
      </div>
    );
  };

  // const carouselFontStyle = {
  //   fontSize: "14px",
  //   lineHeight: "38px",
  //   fontWeight: 700,
  //   textShadow: "0px 4px 4px rgba(0, 0, 0, 0.5)",
  // };
  const carouselHightLightFontStyle = {
    color: "#FFD33A",
    fontSize: "17px",
    fontWeight: 700,
  };

  const _renderCarousel = () => {
    return (
      <Carousel autoplay draggable swipeToSlide autoplaySpeed={4000}>
        {!isEmpty(banners)
          ? map(banners, (item, index) => {
              let allImage = map(banners, "imgLink");
              return (
                <div className="mt-1 relative pr-2 pl-1">
                  {
                    <LightBoxGallery
                      images={compact(allImage)}
                      currentIndex={index}
                    >
                      {(data, setCurrentIndex, setImageVisible) => {
                        return (
                          <img
                            src={get(item, "imgLink")}
                            className="w-full  object-fill object-center"
                            style={contentStyle}
                            onClick={() => {
                              setImageVisible(true);
                            }}
                          />
                        );
                      }}
                    </LightBoxGallery>
                  }
                </div>
              );
            })
          : ""}
        <div className=" relative pr-2">
          <img
            src={blueBanner2Img}
            className="w-full  object-cover object-center waveShape"
            style={contentStyle}
          />
          <div
            className="absolute text-lg text-white font-bold"
            style={{
              bottom: "17%",
              right: "7%",
              fontWeight: 500,
              fontSize: "14px",
              lineHeight: "21px",
            }}
          >
            <div className="flex justify-start">想在理财上有所突破？</div>
            <div className="flex justify-start ">
              <div className="">
                让 <span style={carouselHightLightFontStyle}>IndoITS</span>
              </div>
            </div>
            <div className="flex justify-start ">
              <div className="">
                帮你赚取{" "}
                <span
                  style={{
                    color: "#FFD33A",
                    fontSize: "17px",
                    fontWeight: 700,
                  }}
                >
                  被动收入！
                </span>
              </div>
            </div>
          </div>
          {/* {logoBanner("white")} */}
        </div>
        <div className=" relative pr-2">
          <img
            src={robotBannerImg}
            className="w-full  object-cover object-center waveShape"
            style={contentStyle}
          />
          <div
            className="absolute text-lg text-white font-bold"
            style={{
              top: "32%",
              left: "6%",
              fontWeight: 500,
              fontSize: "14px",
              lineHeight: "21px",
            }}
          >
            <div className="flex justify-start">
              <div>
                通过{" "}
                <span style={{ ...carouselHightLightFontStyle }}>IndoITS</span>
              </div>
            </div>
            <div className="flex justify-start">
              <div className="pt-1">
                <span style={{ ...carouselHightLightFontStyle }}>24小时</span>{" "}
                全自动交易
              </div>
            </div>
            <div className="flex justify-start ">
              <div className="pt-1">创造被动式收入</div>
            </div>
          </div>
          {/* {logoBanner("white")} */}
        </div>

        <div className=" relative pr-2">
          <img
            src={blueBanner3Img}
            className="w-full  object-cover object-center waveShape"
            style={contentStyle}
          />
          <div
            className="absolute text-lg text-white font-bold"
            style={{
              top: "21%",
              left: "12%",
              fontWeight: 500,
              fontSize: "14px",
              lineHeight: "21px",
            }}
          >
            <div className="flex justify-start">躺赢模式操作</div>
            <div className="flex justify-start ">
              <div className="pt-1">
                <span style={carouselHightLightFontStyle}>IndoITS</span>{" "}
              </div>
            </div>
            <div className="flex justify-start ">
              <div className="pt-1">
                <span
                  style={{
                    color: "#FFD33A",
                    fontSize: "17px",
                    fontWeight: 700,
                  }}
                >
                  专业 简易 透明 稳健
                </span>
              </div>
            </div>
            <div className="flex justify-start pt-1">人人都可交易</div>
          </div>
          {/* {logoBanner("white")} */}
        </div>

        <div className=" relative pr-2">
          <img
            src={blueBanner4Img}
            className="w-full  object-cover object-center waveShape"
            style={contentStyle}
          />
          <div
            className="absolute text-lg text-white font-bold"
            style={{
              bottom: "21%",
              left: "8%",
              fontWeight: 500,
              fontSize: "14px",
              lineHeight: "21px",
            }}
          >
            <div className="flex justify-start ">
              <div className="pt-1">
                <span
                  style={{
                    color: "#FFD33A",
                    fontSize: "17px",
                    fontWeight: 700,
                  }}
                >
                  IndoITS
                </span>{" "}
                策略交易
              </div>
            </div>
            <div className="flex justify-start ">
              <div className="pt-1">
                <span
                  style={{
                    color: "#FFD33A",
                    fontSize: "17px",
                    fontWeight: 700,
                  }}
                >
                  新手小白
                </span>{" "}
                也可轻送
              </div>
            </div>
            <div className="flex justify-start pt-1">玩转币圈哟 ！</div>
          </div>
          {/* {logoBanner("white")} */}
        </div>

        <div className=" relative pr-2">
          <img
            src={blueBanner5Img}
            className="w-full  object-cover object-center waveShape"
            style={contentStyle}
          />
          <div
            className="absolute text-lg text-white font-bold"
            style={{
              bottom: "17%",
              left: "10%",
              fontWeight: 500,
              fontSize: "14px",
              lineHeight: "21px",
            }}
          >
            <div className="flex justify-start ">
              <div className="pt-1">
                通过{" "}
                <span
                  style={{
                    color: "#FFD33A",
                    fontSize: "17px",
                    fontWeight: 700,
                  }}
                >
                  IndoITS
                </span>
              </div>
            </div>
            <div className="flex justify-start ">
              <div className="pt-1">
                <span
                  style={{
                    color: "#FFD33A",
                    fontSize: "17px",
                    fontWeight: 700,
                  }}
                >
                  公开透明
                </span>{" "}
                的平台
              </div>
            </div>
            <div className="flex justify-start pt-1">
              <div>安心创造您的财富</div>
            </div>
          </div>
          {/* {logoBanner("white")} */}
        </div>
      </Carousel>
    );
  };

  const logoBanner = (colorText) => {
    return (
      <div
        className="absolute"
        style={{
          right: "4%",
          top: "7%",
        }}
      >
        <div className="flex justify-center">
          <img src={newLogoImg} width={26} height={30} />
        </div>
        <div
          className="flex justify-center text-base"
          style={{
            fontWeight: 700,
            textShadow: "0px 4px 4px rgba(0, 0, 0, 0.5)",
            color: colorText,
          }}
        >
          ITS
        </div>
      </div>
    );
  };

  function navigatePage(link, value = "") {
    if (value === "demo") {
      generateDemoAccessKey({
        profileId: get(profile, "demoUserId"),
      })
        .then((res) => {
          let data = get(res, "data");
          let queryStringified = queryString.stringify(data);
          let fullUrl = `${link}?${queryStringified}`;
          window.location.href = fullUrl;
        })
        .catch((err) => {
          message.error(err?.message);
        });
    } else if (value === "demoInternal") {
      // setLoading(true);
      if (!pairingUser.rankingId) {
        message.error(t("noAccess", sourceKey.home), 4);
        setLoading(false);
        return;
      }

      generateDemoInternalAccessKey()
        .then((res) => {
          let data = get(res, "data");
          let queryStringified = queryString.stringify(data);
          let country = "indonesia";
          let fullUrl = `${link}?${queryStringified}&country=${country}`;
          window.location.href = fullUrl;
        })
        .catch((err) => {
          message.error(err?.message);
          setLoading(false);
        });
    } else {
      navigate(link);
    }
  }

  return (
    <Layout>
      <div className="theme-bg-color relative">
        <div
          // className="theme-bg-color px-2 mt-2 absolute z-10"
          className="theme-bg-color px-2 mt-1 z-10"
          style={{
            // boxShadow: "0px 5px 20px rgba(0, 0, 0, 0.5)",
            width: "100%",
            // backgroundColor: "#fac3c6",
            // top: "3%",
          }}
        >
          <AlertModal />
          <div className="grid grid-cols-8 gap-2">
            <div className="flex col-span-8">
              <div
                className="flex justify-start items-center whitebox-bg-color rounded-full px-1 mr-1 cursor-pointer"
                style={{ width: "75%", height: "33px" }}
                onClick={() => {
                  navigate(routes.campaign.to({ action: "view" }));
                }}
              >
                <img
                  className="pt-0.5 mr-2"
                  src={speakerImg}
                  style={{ width: "20px", height: "16px", color: "black" }}
                />

                <Marquee
                  gradient={false}
                  style={{ height: 22 }}
                  pauseOnHover={true}
                  speed={8}
                >
                  {!isEmpty(marqueeData) ? (
                    <>
                      {map(marqueeData, (item, index) => {
                        return (
                          <span className="pr-4 text-sm">
                            {get(item, "content")}
                          </span>
                        );
                      })}
                    </>
                  ) : null}
                </Marquee>
              </div>
              <div
                className="flex justify-between  "
                style={{ width: "25%", height: "33px" }}
              >
                <div>
                  {get(profile, "enableInsertCampaign") === 1 && (
                    <div
                      className=" px-2 cursor-pointer rounded-xl whitebox-bg-color"
                      style={{ paddingBottom: "0.4rem", paddingTop: "0.4rem" }}
                      onClick={() => {
                        navigate(routes.campaign.to());
                      }}
                    >
                      <img
                        src={threeDotImg}
                        style={{ width: "4px", height: "21px" }}
                      />
                    </div>
                  )}
                </div>
                <div className="pt-1.5">
                  <span className="">
                    <Popover
                      className="popover-no-padding"
                      title={
                        <div className="flex justify-between">
                          <span className="pr-4 flex justify-center items-center">
                            {t("notifications", sourceKey.home)}
                          </span>
                          {/* <span>{t("notification", sourceKey.home)}</span> */}
                          {notificationCount !== 0 && (
                            <Button
                              className="main-button-color"
                              loading={readAllLoading}
                              onClick={() => {
                                readAllNotifications();
                              }}
                            >
                              {t("readAll", sourceKey.home)}
                            </Button>
                          )}
                        </div>
                      }
                      content={_renderNotifications()}
                      trigger="click"
                      placement="bottomRight"
                      //placement="bottomLeft"
                    >
                      <Badge count={notificationCount} size="small">
                        <img
                          src={bellIconImg}
                          style={{ width: "19px", height: "22px" }}
                        />
                      </Badge>
                    </Popover>
                  </span>
                </div>
                <div className="pt-1.5">
                  <span
                    className="cursor-pointer"
                    onClick={() => {
                      // props.logoutSuccessful();
                      props.logoutSuccessful();
                      props.clearModal();
                      // message.success(t("logout"));
                      if (typeof window !== `undefined`) {
                        navigate(routes.login.to());
                      }
                    }}
                  >
                    <img
                      src={logoutIconImg}
                      style={{ width: "19px", height: "22px" }}
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="z-0">{_renderCarousel()}</div>
      </div>

      <div
        className=" theme-bg-color w-full relative "
        style={{
          top: -33,
        }}
      >
        <div className="grid grid-cols-7 gap-2 px-2">
          <div className="col-span-4">
            <div className="p-2 my-2  whitegreybox-bg-color">
              <AccountSummary
                user={user}
                profile={profile}
                accessKey={accessKey}
              />
            </div>
            <div className=" px-2 mt-1" style={{ height: 336 }}>
              <CoinDetails user={user} profile={profile} />
            </div>
          </div>
          <div className="col-span-3 ">
            <div
              className="grid grid-cols-4 gap-1 py-6 mt-2 whitegreybox-bg-color"
              style={{ height: "100%" }}
            >
              {map(shortKeys, (item, index) => {
                let bgColor = "navItem-bg-color";
                if (index === shortKeys?.length - 2) {
                  item = "";
                } else if (index === shortKeys?.length - 1) {
                  item = shortKeys[shortKeys?.length - 2];
                }
                let selectedItem = find(allItems1, (i, index) => {
                  return i.value === item;
                });

                if (isEmpty(item)) {
                  return (
                    <div
                      className={`col-span-2  flex justify-center rounded-2xl`}
                    >
                      <span
                        className="inline-block  rounded-xl py-1 "
                        style={{ width: "100%" }}
                        key={`navItems-1-${index}`}
                      >
                        <div className="justify-center items-center flex ">
                          <div
                            className="justify-center items-center flex addNavItem-bg-color py-2 rounded-full"
                            style={{
                              width: "50.38px",
                              height: "50.38px",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setMenusVisible(true);
                            }}
                          >
                            <PlusOutlined style={{ fontSize: "20px" }} />
                          </div>
                        </div>
                      </span>
                    </div>
                  );
                } else {
                  return (
                    <div
                      className={`col-span-2  flex justify-center rounded-2xl`}
                    >
                      {get(selectedItem, "popUpClick") ? (
                        <div
                          style={{
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            if (
                              get(selectedItem, "popUpClick") === "download"
                            ) {
                              setDownloadPopUpVisible(true);
                            }
                          }}
                        >
                          <div
                            className="  justify-center items-center rounded-xl "
                            style={{ width: "100%" }}
                            key={`navItems-1-${index}`}
                          >
                            <div className="justify-center items-center flex ">
                              <div
                                className="justify-center items-center flex navItem-bg-color py-2 rounded-full"
                                style={{
                                  width: "50.38px",
                                  height: "50.38px",
                                }}
                              >
                                <img
                                  src={selectedItem.icon}
                                  style={{ width: 25, height: 22 }}
                                />
                              </div>
                            </div>

                            <div className="text-xs  mt-1 justify-center items-center flex">
                              {t(get(selectedItem, "value"), sourceKey.home)}
                            </div>
                          </div>
                        </div>
                      ) : (
                        // <Link
                        //   to={selectedItem.path}
                        //   state={{ prevPath: location.pathname }}
                        // >
                        <span
                          className="inline-block  rounded-xl "
                          style={{ width: "100%" }}
                          key={`navItems-1-${index}`}
                          onClick={() => {
                            navigatePage(
                              selectedItem?.path,
                              selectedItem?.value
                            );
                          }}
                        >
                          <div className="justify-center items-center flex ">
                            <div
                              className="justify-center items-center flex navItem-bg-color py-2 rounded-full"
                              style={{
                                width: "48.38px",
                                height: "48.38px",
                              }}
                            >
                              <img
                                src={selectedItem.icon}
                                style={{ width: 22, height: 20 }}
                              />
                            </div>
                          </div>
                          <div className="text-xs  semibold text-center ">
                            {t(get(selectedItem, "value"), sourceKey.home)}
                          </div>
                        </span>
                        // </Link>
                      )}
                    </div>
                  );
                }
              })}
            </div>
          </div>
        </div>

        <DownloadPopUpModal
          visible={downloadPopUpVisible}
          onClose={() => {
            setDownloadPopUpVisible(false);
          }}
        />

        <ShortKeysMenuModal
          visible={menusVisible}
          profile={profile}
          accessKey={accessKey}
          data={shortKeys}
          onClose={() => {
            setMenusVisible(false);
            getProfile();
          }}
        />
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  app: state.app,
});

const mapDispatchToProps = {
  updateActiveId,
  updateGlobalModalVisible,
  triggerModal,
  logoutSuccessful,
  clearModal,
};
export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
