import { Carousel, message, Skeleton } from "antd";
import {
  filter,
  forEach,
  get,
  isEmpty,
  map,
  toLower,
  toNumber,
  toString,
  uniqBy,
} from "lodash";
import React, { useEffect, useState } from "react";
import Image from "./Image";
import getHighFrequencyRobots from "../../../newApi/robots/getHighFrequencyRobots";
import getSymbolList from "../../../newApi/robots/getSymbolList";
import {
  formatDecimalNumber,
  formatNumber,
  getCoinTypeIcon,
} from "../../../utilities/common-function";
import { numberDecimal } from "../../../utilities/startUp";
import SymbolPriceDisplay from "../../robot/components/SymbolPriceDisplay";

const positiveColor = "#4BB543";

const initial = [
  {
    symbol: "BTCUSDT",
    symbolImg: "BTC",
    price: 0,
  },
  {
    symbol: "ETHUSDT",
    symbolImg: "ETH",
    price: 0,
  },
  {
    symbol: "BNBUSDT",
    symbolImg: "BNB",
    price: 0,
  },
];
let defaultSymbol = [
  {
    symbol: "BTCUSDT",
    platform: "binance",
    symbolImg: "BTC",
  },
  {
    symbol: "ETHUSDT",
    platform: "binance",
    symbolImg: "ETH",
  },
  {
    symbol: "BNBUSDT",
    platform: "binance",
    symbolImg: "BNB",
  },
];

const MainSocketCoinsDetails = (props) => {
  const [coinListing, setCoinListing] = useState([]);
  const [newCoinData, setNewCoinData] = useState({});
  const [symbolList, setSymbolList] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const user = get(props, "user");
  const coinStructure = get(props, "coinStructure");

  let interval;
  const MINUTE_MS = 3000;

  useEffect(() => {
    // getData();
    getHFRobot();
  }, [props.profile]);

  // console.log("out coint", coinListing);

  function getHFRobot() {
    let profile = get(props, "profile");
    if (!isEmpty(profile)) {
      let robotProfiles = get(profile, "robotProfiles");

      if (!isEmpty(robotProfiles)) {
        robotProfiles = map(robotProfiles, (item, index) => {
          return get(item, "robotProfileId");
        });
        // console.log(robotProfiles);
        getHighFrequencyRobots({
          profileIdIn: robotProfiles,
          liquidation: 0,
          limit: 500,
          skip: 0,
        })
          .then((res) => {
            // console.log("robot res", res);
            let robot_data = get(res, "data");
            let tempSymbolList = map(robot_data, (item, index) => {
              let convertedSymbol = get(item, "symbol")?.replace("USDT", "");

              return {
                symbol: get(item, "symbol"),
                symbolImg: convertedSymbol,
                platform: get(item, "exchange"),
              };
            });

            defaultSymbol.push(...tempSymbolList);
            defaultSymbol = uniqBy(defaultSymbol, "symbol");
            // console.log("defaultSymbol", defaultSymbol);
            setSymbolList(defaultSymbol);
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        setSymbolList(defaultSymbol);
      }
    }
  }
  return (
    <>
      {/* <Divider >
				<div className="text-base text-white font-semibold text-center">
					Binance 币安交易所行情
				</div>
			</Divider> */}

      <Carousel
        slidesToShow={coinListing.length > 3 ? 4 : 3}
        dotPosition="right"
        vertical
        verticalSwiping
        slidesToScroll={1}
        draggable
        autoplay
        style={{ margin: 0 }}
        className="grid grid-cols-4 gap-1"
      >
        {/* {isEmpty(coinListing) && (
          <div className="col-span-2 m-2 pr-4">
            <div className=" m-2 px-4 py-2 coin-bg-color rounded-3xl">
              <div className="text-sm text-center mb-2 font-semibold">
                <Skeleton.Button active={true} size="small" />
              </div>
              <div className="mb-2 pl-2 flex justify-center">
                <Skeleton.Button active={true} size="small" />
              </div>
              <div className="text-xs flex justify-center items-center font-medium font-bold">
                <Skeleton.Button active={true} size="small" />
              </div>
            </div>
          </div>
        )} */}
        {map(isEmpty(symbolList) ? initial : symbolList, (item, index) => {
          return (
            <div className="col-span-4 my-1 ">
              <div className="pt-1 rounded-xl whitegreybox-bg-color">
                <div className="text-sm text-center mb-1  ">
                  {get(item, "symbolImg")}
                </div>
                <div className="my-2  flex justify-center">
                  <Image
                    src={getCoinTypeIcon(toLower(get(item, "symbolImg")))}
                    style={{ width: 25, height: 25 }}
                  />
                </div>
                <div
                  className="text-xs flex justify-center items-center font-medium font-bold"
                  style={
                    {
                      // color:
                      // get(item, "increase") === true ? positiveColor : "red",
                    }
                  }
                >
                  <div className="text-white">

                    <SymbolPriceDisplay
                      symbol={item?.symbol}
                      coinStructure={coinStructure}
                    // onSetPrice={(data) => {
                    //   console.log("price data", data);
                    // }}
                    />
                  </div>
                </div>
                <div
                  className="text-xs flex justify-center items-center font-medium font-bold"
                  style={
                    {
                      // color:
                      // get(item, "increase") === true ? positiveColor : "red",
                    }
                  }
                >
                  <div></div>
                </div>
              </div>
            </div>
          );
        })}
      </Carousel>
    </>
  );
};

export default MainSocketCoinsDetails;
