import { notificationPrefix } from ".";
import { apiUrl, _base_axios_post } from "..";

// const PAGESIZE = 30;

export default function patchRead(query = {}, accessKey = "") {
  return _base_axios_post(
    `${apiUrl}/${notificationPrefix}/patchRead`,
    query,
    accessKey
  );
}
