import React, { useEffect, useState } from "react";
import { coinsIcon } from "../../../images";

export default function App(props) {
  useEffect(() => {
    setSrc(props.src);
  }, [props.src]);
  const [src, setSrc] = useState(props.src);
  return <img {...props} src={src} onError={() => setSrc(coinsIcon)} />;
}
