import { Area } from "@ant-design/plots";
import {
  cloneDeep,
  filter,
  forIn,
  get,
  isEmpty,
  isUndefined,
  map,
  set,
} from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "../../../locales/useTranslation";
import getProfileDailyProfit from "../../../newApi/robots/getProfileDailyProfit";
import getProfileRobotProfitSummary from "../../../newApi/robots/getProfileRobotProfitSummary";

const roundingDecimal = 2;
const robotArray = [
  {
    _id: "1234a1231",
    title: "robot 1",
  },
  {
    _id: "2222a1231",
    title: "robot 2",
  },
  {
    _id: "3333a1231",
    title: "robot 3",
  },
  {
    _id: "4444a1231",
    title: "robot 4",
  },
  {
    _id: "55555a1231",
    title: "robot 5",
  },
  {
    _id: "6666a1231",
    title: "robot 6",
  },
  {
    _id: "7777a1231",
    title: "robot 7",
  },
];

const robotTypes = ["HF", "HF1", "HF3"];

const dataArray = [
  {
    _id: new Date("03/05/2022"),
    totalProfit: 50,
    totalCommission: 100,
  },
  {
    _id: new Date("04/06/2022"),
    totalProfit: 20,
    totalCommission: 10,
  },
  {
    _id: new Date("05/07/2022"),
    totalProfit: 500,
    totalCommission: 5,
  },
  {
    _id: new Date("12/01/2022"),
    totalProfit: 16,
    totalCommission: 2,
  },
  {
    _id: new Date("03/03/2022"),
    totalProfit: 88,
    totalCommission: 288,
  },
];

const ProfitDetails = (props) => {
  const [dataSource, setDataSource] = useState([]);
  const [robotList, setRobotList] = useState([]);
  const [date, setDate] = useState([]);
  const [robot, setRobot] = useState(robotArray);
  const [type, setType] = useState("HF");
  const [dates, setDates] = useState([null, null]);
  const [hackValue, setHackValue] = useState(null);
  const [value, setValue] = useState(null);
  const [filterGroup, setFilterGroup] = useState({});
  const [checkAll, setCheckAll] = useState(false);
  const [userTotalProfit, setUserTotalProfit] = useState(0);
  const [symbolList, setSymbolList] = useState([]);
  const [symbol, setSymbol] = useState("");
  const [totalAmount, setTotalAmount] = useState(0);
  const [capitalAmount, setCapitalAmount] = useState(0);
  const [dateRange, setDateRange] = useState([]);
  const [firstPlayDate, setFirstPlayDate] = useState(null);
  const { t } = useTranslation();

  // const [profile, setProfile] = useState({});
  const user = get(props, "users.user");
  const profile = get(props, "profile");

  useEffect(() => {
    //getSymbol();
    // getActiveRobot();
    getTotalProfit();
    getData();
    if (!isEmpty(profile)) {
      let capital = get(profile, "wallet.highFrequency.capital");
      let capitalAmountN = 0;
      forIn(capital, (value, key) => {
        capitalAmountN = capitalAmountN + value;
      });
      let limit = get(profile, "wallet.highFrequency.limit");
      let limitAmount = 0;
      forIn(limit, (value, key) => {
        limitAmount = limitAmount + value;
      });

      // console.log("capitalAmount,,", capitalAmountN);
      // console.log("limitAmount,,", limitAmount);

      if (
        isNaN(capitalAmountN) ||
        isUndefined(capitalAmountN) ||
        capitalAmountN === 0
      ) {
        setCapitalAmount(limitAmount);
      } else {
        setCapitalAmount(capitalAmountN);
      }
    }
  }, [props.profile]);

  // useEffect(() => {
  //   getData();
  // }, [totalAmount]);

  useEffect(() => {
    getData();
    getTotalProfit();
  }, [filterGroup, type]);

  // useEffect(() => {
  //   getSymbol();
  // }, [type]);

  useEffect(() => {
    getData();
  }, [symbol]);

  function getData(skip) {
    if (isNaN(parseInt(skip))) {
      skip = 0;
    } else {
      skip = parseInt(skip);
    }
    let robotProfilesData = get(profile, "robotProfiles");
    robotProfilesData = map(robotProfilesData, (item, index) => {
      return get(item, "robotProfileId");
    });
    if (!isEmpty(profile) && !isEmpty(robotProfilesData)) {
      getProfileDailyProfit(
        "all",
        0,
        //getDailyProfitReport('all', 0,
        {
          profileIdIn: robotProfilesData,
          //strategyCode: type,
          type: "day",
          //symbol: symbol,
          ...filterGroup,
        }
      )
        .then((res) => {
          // console.log("profit", res);
          // if (isEmpty(dateRange)) {
          //   setDateRange([get(res, "firstDate"), get(res, "lastDate")]);
          // }
          // if (firstPlayDate === null) {
          //   setFirstPlayDate(get(res, "robotPlayDay") || null);
          // }
          let data = get(res, "data");
          let startAndEndDate = [];
          let temp_dataSource = [];
          let diffDays = 7;

          if (isEmpty(date)) {
            let firstDate = new Date();
            firstDate.setDate(firstDate.getDate() - 6);
            startAndEndDate = [firstDate, new Date()];
          } else {
            startAndEndDate = cloneDeep(date);
            diffDays =
              findDayDifference(startAndEndDate[0], startAndEndDate[1]) + 1;
          }
          let startDate = startAndEndDate[0];
          let endDate = startAndEndDate[1];
          let singleDay = startDate;

          for (let index = 0; index < diffDays; index++) {
            if (index != 0) {
              singleDay = moment(singleDay).add(1, "days");
            }

            let filtered_data = filter(data, (item) => {
              //return get(item,"_id.date") === moment(singleDay).format('YYYY-MM-DD');
              return (
                get(item, "date") === moment(singleDay).format("YYYY-MM-DD")
              );
            });

            let totalProfitDay = 0;
            if (!isEmpty(filtered_data)) {
              filtered_data.forEach((element) => {
                let totalProfit = get(element, "totalProfit");
                totalProfitDay = totalProfitDay + totalProfit;
              });
            }

            let returnPercentage = 0;
            let allCapital = get(profile, "wallet.highFrequency.capital");
            let allLimit = get(profile, "wallet.highFrequency.limit");
            let capitalAmount = 0;

            if (isEmpty(allCapital)) {
              forIn(allLimit, (value, key) => {
                capitalAmount = capitalAmount + value;
              });
            } else {
              forIn(allCapital, (value, key) => {
                capitalAmount = capitalAmount + value;
              });
            }
            // console.log("capital", capitalAmount);

            if (totalProfitDay !== 0) {
              if (capitalAmount === 0) {
                // console.log("here");
                returnPercentage = 0;
              } else {
                totalProfitDay = Math.round(totalProfitDay * 100) / 100;
                returnPercentage = (totalProfitDay / capitalAmount) * 100;
                returnPercentage = Math.round(returnPercentage * 100) / 100;
                //totalProfitDay = formatAmount(totalProfitDay);
              }
            }

            let correctValue = {
              totalProfitDay: totalProfitDay,
              returnPercentage: returnPercentage,
              _id: moment(singleDay).format("L"),
            };

            temp_dataSource.push(correctValue);
          }

          //console.log("temp_dataSource",temp_dataSource);
          setDataSource(temp_dataSource);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  function findDayDifference(date1, date2) {
    return Math.floor(Math.abs(date2 - date1) / (1000 * 60 * 60 * 24));
  }

  function getTotalProfit() {
    let robotProfilesData = get(profile, "robotProfiles");
    robotProfilesData = map(robotProfilesData, (item, index) => {
      return get(item, "robotProfileId");
    });
    if (!isEmpty(profile) && !isEmpty(robotProfilesData)) {
      getProfileRobotProfitSummary(1, 0, {
        profileIdIn: robotProfilesData,
      })
        .then((res) => {
          setFirstPlayDate(get(res, "startDate"));
          setUserTotalProfit(get(res, "totalProfit"));
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  const plotBarChart = () => {
    let data = [];

    if (!isEmpty(dataSource)) {
      dataSource.forEach((element) => {
        let robotObj = {};
        set(robotObj, "day", moment(get(element, "_id")).format("DD/MM/YYYY"));
        set(robotObj, "Profit", get(element, "returnPercentage"));
        set(robotObj, "amount", get(element, "totalProfitDay"));
        data.push(robotObj);
      });
    }

    const config = {
      data,
      xField: "day",
      yField: "amount",
      padding: [10, 10, 10, 10],
      color: "#f57f83",
      maxColumnWidth: 30,
      xAxis: {
        title: false,
        label: false,
      },
      yAxis: false,
      label: false,
      meta: {
        amount: {
          alias: "Profit",
        },
      },
      smooth: true,
      line: {
        color: "red",
        size: 1,
      },
      //   point: {
      //     size: 1,
      //     style: {
      //       fill: "#FFDD02",
      //     },
      //   },
    };

    return (
      <div className="">
        <Area
          className="pt-4 bg-white rounded-2xl"
          style={{ height: "65px" }}
          {...config}
          autoFit={true}
        />
      </div>
    );
  };

  return <>{plotBarChart()}</>;
};

export default ProfitDetails;
