import { CheckOutlined } from "@ant-design/icons";
import { Badge, message, Modal, Spin } from "antd";
import {
  cloneDeep,
  compact,
  filter,
  get,
  indexOf,
  isEmpty,
  map,
  remove,
} from "lodash";
import React, { useEffect, useState } from "react";
import { feathers } from "../../../api/feathers_rest";
import { sourceKey } from "../../../locales/config";
import { useTranslation } from "../../../locales/useTranslation";
import { allItems1 } from "../../general/config";
import patchShortKeys from "../../../newApi/profile/patchShortKeys";

let timeoutFunc;

// markup
const MessageModal = (props) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [allItems, setAllItems] = useState([]);
  const [shortKeysData, setShortKeysData] = useState([]);
  const [profile, setProfile] = useState({});

  let accessKey = props.accessKey;

  useEffect(() => {
    setVisible(props.visible === true);
  }, [props.visible]);

  useEffect(() => {
    // let defaultKeyItems = defaultItems;
    // defaultKeyItems = map(defaultKeyItems, "value");
    let defaultKeyItems = ["demo", "revenue"];
    let all = allItems1;
    all = filter(allItems1, (item, index) => {
      let value = get(item, "value");
      return indexOf(defaultKeyItems, value) === -1;
    });
    setAllItems(all);
  }, []);

  useEffect(() => {
    if (!isEmpty(props.data)) {
      let data = compact(props.data);

      setShortKeysData(data);
    }
  }, [props.data]);

  useEffect(() => {
    if (!isEmpty(props.profile)) {
      setProfile(props.profile);
    }
  }, [props.profile]);

  function close() {
    if (props.onClose) {
      props.onClose();
    } else {
      setVisible(false);
    }
  }

  function handleOk() {
    setLoading(true);
    if (props.onOk) {
      clearTimeout(timeoutFunc);
      setTimeout(() => {
        props.onOk();
        setLoading(false);
      }, 200);
    }
  }

  function handleAddShortKey(item) {
    // console.log(item);
    let tempShortKeys = cloneDeep(shortKeysData);
    tempShortKeys.push(get(item, "value"));
    if (tempShortKeys.length > 9) {
      message.error(t("shortCutAllow", sourceKey.home));
    } else {
      setShortKeysData(tempShortKeys);
    }
  }

  function handleRemoveShortKey(item) {
    let value = get(item, "value");
    let tempShortKeys = cloneDeep(shortKeysData);
    let removedItem = remove(tempShortKeys, (i, index) => {
      return i === value;
    });

    setShortKeysData(tempShortKeys);
  }

  function handleSubmit() {
    // feathers("profiles", accessKey)
    //   .patch(get(profile, "_id"), {
    //     shortKey: shortKeysData,
    //   })
    patchShortKeys({
      shortKeys: shortKeysData,
    })
      .then((res) => {
        message.success(t("success"));
        close();
      })
      .catch((err) => {
        console.log(err);
        message.error(err?.message);
      });
  }

  return (
    <React.Fragment>
      <Spin spinning={loading}>
        <Modal
          wrapClassName="no-padding-modal-body modal-body-background-transparent"
          centered
          maskClosable
          mask
          footer={null}
          className=""
          visible={visible}
          onCancel={() => {
            close();
          }}
        >
          <div className="lightgreybox-bg-color rounded-3xl px-5 py-10">
            <div className="flex justify-center font-semibold text-base my-1">
              {t("shortCut", sourceKey.home)}
            </div>
            <div className="grid grid-cols-8">
              {map(allItems, (selectedItem, index) => {
                let showBadge = false;
                if (indexOf(shortKeysData, get(selectedItem, "value")) !== -1) {
                  showBadge = true;
                }
                return (
                  <div className="col-span-2 flex justify-center rounded-2xl">
                    <span
                      className="inline-block  rounded-xl py-1 "
                      style={{ width: "100%" }}
                      key={`navItems-1-${index}`}
                    >
                      <div className="justify-center items-center flex ">
                        {showBadge === true ? (
                          <Badge
                            count={
                              <CheckOutlined
                                style={{ color: "white", fontSize: "11px" }}
                              />
                            }
                            style={{
                              backgroundColor: "#52c41a",
                              borderRadius: "50%",
                              padding: "3px",
                            }}
                          >
                            <div
                              className="justify-center items-center flex navItem-bg-color py-2 rounded-full"
                              style={{
                                width: "50.38px",
                                height: "50.38px",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                handleRemoveShortKey(selectedItem);
                              }}
                            >
                              <img
                                src={selectedItem.icon}
                                style={{ width: 25, height: 22 }}
                              />
                            </div>
                          </Badge>
                        ) : (
                          <div
                            className="justify-center items-center flex navItem-bg-color py-2 rounded-full"
                            style={{
                              width: "50.38px",
                              height: "50.38px",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              handleAddShortKey(selectedItem);
                            }}
                          >
                            <img
                              src={selectedItem.icon}
                              style={{ width: 25, height: 22 }}
                            />
                          </div>
                        )}
                      </div>
                      <div className="text-xs  semibold text-center ">
                        {t(get(selectedItem, "value"), sourceKey.home)}
                      </div>
                    </span>
                  </div>
                );
              })}
            </div>

            <div
              className="main-button-color p-2 mt-2 rounded-3xl cursor-pointer"
              onClick={() => {
                handleSubmit();
              }}
            >
              <div className="flex justify-center"> {t("done")} </div>
            </div>
          </div>
        </Modal>
      </Spin>
    </React.Fragment>
  );
};

export default MessageModal;
