import { forIn, get, isEmpty, map, sumBy } from "lodash";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { sourceKey } from "../../../locales/config";
import { useTranslation } from "../../../locales/useTranslation";
import getTopUp from "../../../newApi/pairing/getTopUp";
import getUserSummary from "../../../newApi/pairing/getUserSummary";
import getProfileRobotProfitSummary from "../../../newApi/robots/getProfileRobotProfitSummary";
import { formatDecimalNumber } from "../../../utilities/common-function";
import { numberDecimal } from "../../../utilities/startUp";
import SmallLineChart from "./SmallLineChart";
// markup
const RewardListing = (props) => {
  const { t } = useTranslation();
  const [totalRobotProfit, setTotalRobotProfit] = useState(0);
  const [totalRewardProfit, setTotalRewardProfit] = useState(0);
  const [totalCapital, setTotalCapital] = useState(0);
  const user = get(props, "user");
  const profile = get(props, "profile");
  const accessKey = get(props, "accessKey");

  useEffect(() => {
    getRewardData();
    getRobotTotalProfit();
    getCapital();
  }, []);

  useEffect(() => {
    getRobotTotalProfit();
  }, [props.profile]);

  function getRewardData() {
    if (get(user, "user.pairingUser._id")) {
      getUserSummary(
        "all",
        0,
        {
          userId: get(user, "user.pairingUser._id"),
        },
        accessKey
      )
        .then((res) => {
          // console.log({ res });
          let data = get(res, "data");
          let total = 0;
          forIn(data, (value, key) => {
            total += value;
          });
          // console.log("reward total", total);
          setTotalRewardProfit(total || 0);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  function getCapital() {
    if (get(user, "user.pairingUser._id")) {
      getTopUp(
        "all",
        0,
        {
          userId: get(user, "user.pairingUser._id"),
        },
        accessKey
      )
        .then((res) => {
          // console.log("topUp", res);
          let data = get(res, "data");
          let totalAmount = sumBy(data, "amount");
          setTotalCapital(totalAmount || 0);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  function getRobotTotalProfit() {
    let robotProfilesData = get(profile, "robotProfiles");
    robotProfilesData = map(robotProfilesData, (item, index) => {
      return get(item, "robotProfileId");
    });
    // console.log(robotProfilesData);
    if (!isEmpty(robotProfilesData)) {
      getProfileRobotProfitSummary(1, 0, {
        profileIdIn: robotProfilesData,
      })
        .then((res) => {
          // console.log("total", res);
          // console.log("totalProfit", get(res, "totalProfit"));
          setTotalRobotProfit(get(res, "totalProfit") || 0);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  const _renderTypes = () => {
    return (
      <>
        <div className="">
          <div className="grid grid-cols-4 gap-3 text-base ">
            <div
              // className="col-span-4  rounded-xl px-4 mx-4 sm:mx-6 py-2"
              className="col-span-4  rounded-xl  relative text-sm"
              // style={{
              //   backgroundImage: `url(${summaryBack3Img})`,
              //   backgroundPosition: "center",
              //   backgroundSize: "cover",
              //   backgroundRepeat: "no-repeat",
              //   height: 67,
              // }}
            >
              <div>
                <SmallLineChart
                  users={get(props, "user.user")}
                  profile={profile}
                />
              </div>
              <div className="flex justify-start">
                <div className="inline-block  text-sm">
                  {t("capital", sourceKey.home)}
                </div>

                <div className="inline-block w-1/2  text-right">
                  {formatDecimalNumber(totalCapital, numberDecimal)}
                </div>
              </div>
              <div className="flex justify-start ">
                <div className=" inline-block text-sm">
                  {t("profit", sourceKey.home)}
                </div>

                <div className="inline-block w-1/2  text-right ">
                  {formatDecimalNumber(
                    totalRewardProfit + totalRobotProfit,
                    numberDecimal
                  ) || 0}{" "}
                  {/* 1,000,000.00 */}
                </div>
                <div
                  className="inline-block  pt-0.5 pl-0.5 align-bottom	"
                  style={{ fontSize: "0.65rem" }}
                >
                  {" "}
                  (
                  {formatDecimalNumber(
                    totalCapital === 0
                      ? 0
                      : ((totalRewardProfit + totalRobotProfit) /
                          totalCapital) *
                          100,
                    numberDecimal
                  )}
                  % )
                </div>
              </div>
              {/* <div
                className="absolute"
                style={{
                  right: "7%",
                  bottom: "13%",
                }}
              >
                <img
                  src={newLogoImg}
                  width="50px"
                  height="49.83px"
                  // className="w-full  object-cover object-center"
                  style={{
                    opacity: 0.7,
                  }}
                />
              </div> */}
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <React.Fragment>
      <div className="">{_renderTypes()}</div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  app: state.app,
});

const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(RewardListing);
