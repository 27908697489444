import { useLocation } from "@reach/router";
import { message } from "antd";
import axios from "axios";
import { navigate } from "gatsby";
import { get, includes, isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import client from "../../../../feathers";
import { useTranslation } from "../../../locales/useTranslation";
import { clearModal, triggerModal } from "../../../redux/actions/app-actions";
import { logoutSuccessful } from "../../../redux/actions/user-actions";
import { routes } from "../../../route";

// markup
const Layout = (props) => {
  const location = useLocation();
  const { t, languages } = useTranslation();
  const currentPath =
    location.pathname[location.pathname.length - 1] === "/" &&
    location.pathname.length > 1
      ? location.pathname.slice(0, -1)
      : location.pathname;
  const accessKey = get(props.user, "accessKey");
  const [loggedIn, setLoggedIn] = useState(false);
  const [skipAlert, setSkipAlert] = useState(false);

  message.config({
    top: 10,
    duration: 1,
    maxCount: 1,
  });

  useEffect(() => {
    if (isEmpty(accessKey)) {
      setLoggedIn(false);
      navigate(routes.login.to());
    } else {
      setLoggedIn(true);
    }
  }, [accessKey]);

  useEffect(() => {
    if (
      get(location, "pathname") != routes.login.to() &&
      get(location, "pathname") != routes.forgotPassword.to()
    ) {
      if (
        includes(get(location, "pathname"), "email_verification") ||
        includes(get(location, "pathname"), "reset_password")
      ) {
        //skip authorization
      } else {
        axios
          .post(
            `${client.io.io.uri}feather-authenticate`,
            {},
            {
              // accessToken : accessKey,
              headers: {
                Authorization: "Bearer " + accessKey,
              },
            }
          )
          .then((res) => {})
          .catch(function (error) {
            // console.log(error);
            // message.error("Session Expired. Please Login Again");

            navigate(routes.login.to());
          });
      }
    }
  }, []);

  return <div className="">{loggedIn && props.children}</div>;
};

const mapStateToProps = (state) => ({
  user: state.user,
  app: state.app,
});

const mapDispatchToProps = {
  logoutSuccessful,
  clearModal,
  triggerModal,
};
export default connect(mapStateToProps, mapDispatchToProps)(Layout);
