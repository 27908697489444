import { apiUrl, _axios_base_get_list } from "..";
import { notificationPrefix } from "./index";
// const PAGESIZE = 100;

export default function getNotifications(
  limit,
  skip = 0,
  query = {},
  accessKey
) {
  return _axios_base_get_list(
    `${apiUrl}/${notificationPrefix}/getNotifications`,
    limit,
    skip,
    query,
    accessKey
  );
}
