import { Skeleton } from "antd";
import { get, isEmpty } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import io from "socket.io-client";
import { formatDecimalNumber } from "../../../utilities/common-function";
import { socketUrl } from "../../../utilities/startUp";

// markup
const SymbolPriceDisplay = (props) => {
  // const location = useLocation();
  //   console.log(price);
  const [priceValue, setPriceValue] = useState({});
  const { symbol, coinStructure } = props;
  // console.log(symbol);
  // console.log(coinStructure);

  useEffect(() => {
    if (!isEmpty(symbol)) {
      const socket = io(socketUrl, {
        origins: socketUrl,
        query: {
          symbols: [symbol],
        },
      });

      socket.on("connect", () => {
        // console.log("Connect to socket");
      });

      socket.on("common", (data) => {
        // console.log("Receive price: ", data);
        let priceData = get(data, symbol);
        setPriceValue(priceData || {});

        if (props?.onSetPrice) {
          props.onSetPrice(priceData);
        }

        // updatePrice(data);
        // store.dispatch(updatePrice(data));
      });

      return () => {
        socket.disconnect();
      };
    }
  }, [symbol]);

  const _renderPrice = useMemo(() => {
    return (
      <span>
        {isEmpty(priceValue) ? (
          <Skeleton.Button />
        ) : (
          <div
            style={{
              color: `${priceValue?.changePercentage >= 0 ? "#4BB543" : "red"}`,
            }}
          >
            <div className="flex justify-center">
              {formatDecimalNumber(
                priceValue?.price || 0,
                get(coinStructure, `${symbol}.price`) || 4
              )}
            </div>
            <div className="flex ">
              {priceValue?.changePercentage || 0}%
            </div>
          </div>
        )}
      </span>
    );
  }, [priceValue]);

  return <React.Fragment>{_renderPrice}</React.Fragment>;
};

export default SymbolPriceDisplay;
