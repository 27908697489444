import { campaignPrefix } from ".";
import { apiUrl, _axios_base_get_list } from "..";
// const PAGESIZE = 100;

export default function getCampaigns(
  limit,
  skip = 0,
  query = {},
  accessKey = ""
) {
  return _axios_base_get_list(
    `${apiUrl}/${campaignPrefix}/getCampaigns`,
    limit,
    skip,
    query,
    accessKey
  );
}
