import { isPlainObject } from "lodash";
import { apiUrl, _base_axios_get } from "..";
import { robotPrefix } from "./index";

export default async function getSymbolList(query = {}) {
  if (!isPlainObject(query)) {
    query = {};
  }

  return await _base_axios_get(`${apiUrl}/${robotPrefix}/getSymbolList`, query);
}
