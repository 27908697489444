import * as React from "react";
import Main from "../components/general/components/Main";
import SharedHeader from "../components/general/components/SharedHeader";
import HomePage from "../components/homepage/pages/HomePage";

// markup
const Index = (props) => {
  return (
    <React.Fragment>
      <SharedHeader />
      <Main>
        <HomePage {...props} />
      </Main>
    </React.Fragment>
  );
};

export default Index;
